import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import Marquee from "react-fast-marquee";
import apple from './../../images/companies/apple-logo-color.png';
import amazon from './../../images/companies/amazon-logo-color.png';
import airIndia from './../../images/companies/air-india-logo-color.png';
import bayer from './../../images/companies/bayer-logo-color.png';
import zoho from './../../images/companies/zoho-logo-color.png';
import hisense from './../../images/companies/hisense-logo-color.png';

export const CompanyTestimonials = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {(isMobile ?
                <Box maxWidth="960px" alignItems="center" margin="auto" mt={4}>
                    < Marquee >
                        <img src={apple} alt="apple logo" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px' }} />
                        <img src={amazon} alt="amazon logo" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px' }} />
                        <img src={airIndia} alt="AirIndia logo" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px' }} />
                        <img src={bayer} alt="Bayer logo" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px' }} />
                        <img src={zoho} alt="Zoho logo" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px' }} />
                        <img src={hisense} alt="Hisense logo" style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px' }} />
                    </Marquee >
                </Box >
                :
                <Box maxWidth="960px" alignItems="center" margin="auto" mt={4}>
                    <Marquee>
                        <img src={apple} alt="apple logo" style={{ width: '100px', paddingLeft: '27px', paddingRight: '27px' }} />
                        <img src={amazon} alt="amazon logo" style={{ width: '100px', paddingLeft: '27px', paddingRight: '27px' }} />
                        <img src={airIndia} alt="AirIndia logo" style={{ width: '100px', paddingLeft: '27px', paddingRight: '27px' }} />
                        <img src={bayer} alt="Bayer logo" style={{ width: '100px', paddingLeft: '27px', paddingRight: '27px' }} />
                        <img src={zoho} alt="Zoho logo" style={{ width: '100px', paddingLeft: '27px', paddingRight: '27px' }} />
                        <img src={hisense} alt="Hisense logo" style={{ width: '100px', paddingLeft: '27px', paddingRight: '27px' }} />
                    </Marquee>
                </Box>
            )}
            <TrustedBy />
        </>
    )
}

const TrustedBy = () => {
    return (
        <Box mt={2}>
            <Typography variant="caption" component="h4" color="primary">
                Trusted by Fortune 500, Governments, Agencies and Small & Medium-Sized Businesses
            </Typography>
        </Box>
    )
}