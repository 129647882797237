import { Box, Grid, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import { ImageToCaptionCard } from "./imageToCaptionCard";
import insta1 from '../../images/insta1.jpg';
import insta2 from '../../images/insta2.jpg';
import insta3 from '../../images/insta3.jpg';
import insta4 from '../../images/insta4.jpg';
import insta5 from '../../images/insta5.jpg';
import insta6 from '../../images/insta6.jpg';
import insta7 from '../../images/insta7.jpg';
import insta8 from '../../images/insta8.jpg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const SoFar = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const examples = [
        {
            imageSrc: insta6,
        },
        {
            imageSrc: insta2,
        },
        {
            imageSrc: insta1,
        },
        {
            imageSrc: insta7,
        },
        {
            imageSrc: insta5,
        },
        {
            imageSrc: insta8,
        }
    ]

    return (
        <Box>
            <Typography variant="h4" component="h3" pb={4}>Can you believe those captions have been created with our AI?🤯</Typography>
            {(isMobile ?
                <Carousel interval={4000} infiniteLoop autoPlay={true} showThumbs={false} showStatus={false} dynamicHeight={false} useKeyboardArrows={true}>
                    {examples.map((example, index) => (
                        <img src={example.imageSrc} alt="Generated caption" />
                    ))}
                </Carousel>
                :
                <Grid container spacing={4} alignItems="center" justifyContent="center" direction="row">
                    <Grid item>
                        <ImageToCaptionCard imageSrc={insta6} />
                    </Grid>
                    <Grid item>
                        <ImageToCaptionCard imageSrc={insta2} />
                    </Grid>
                    <Grid item>
                        <ImageToCaptionCard imageSrc={insta1} />
                    </Grid>
                    <Grid item>
                        <ImageToCaptionCard imageSrc={insta7} />
                    </Grid>
                    <Grid item>
                        <ImageToCaptionCard imageSrc={insta5} />
                    </Grid>
                    <Grid item>
                        <ImageToCaptionCard imageSrc={insta8} />
                    </Grid>
                </Grid>
            )}
        </Box >
    )
}

