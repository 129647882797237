import { Box } from '@mui/material';

export const BodyBox = ({
    children,
}: {
    children?: React.ReactNode;
}) => {
    return (
        <Box
            sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 5, mb: 5, mr:2, ml:2 }}>
            <Box
                minWidth={{ xs: "300px", sm: "450px", md: "700px", lg: "900px", xl: "1200px" }}
                maxWidth={{ lg: "1200px", xl: "1600px" }}
                minHeight={"300px"}
            >
                {children}
            </Box>
        </Box>
    );
}