import { Avatar, Button, Card, CardActions, CardContent, Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import avatar from './avatar.jpg';

export function Blog() {

    return (
        <Box>
            <Typography variant={'h6'} component={"h1"} pb={2}>Blog Posts</Typography>
            <Grid direction={"row"} container justifyContent="center">
                <Grid item>
                    <MuiXCard
                        title="How to increase Google your websites Google ranking"
                        subtitle="For E-commerce online shop with only 50$ budget in 2024 (no SEO knowledge needed)"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Monday, March 11 2024"
                        post="increase-google-ranking-with-low-budget"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="How to get more organic website traffic"
                        subtitle="For your online shop in 2024 (with AI)"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Monday, March 18 2024"
                        post="get-more-organic-traffic-with-AI"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="3 Reasons why you should stop using chatGPT"
                        subtitle="when writing social media posts in 2024"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Monday, March 25 2024"
                        post="stop-using-chatGPT-for-social-media-posts"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="Create AI Social Media Texts with AI"
                        subtitle="The Ultimate Guide for Effortless Content Creation with chatGPT"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Thursday, April 11 2024"
                        post="create-social-media-texts-with-ai-the-ultimate-guide"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="What are the best tools to create AI social media captions?"
                        subtitle="as a content creator in 2024"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Thursday, April 18 2024"
                        post="the-best-tools-to-create-AI-social-media-captions"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="How to create social media captions with AI for TikTok"
                        subtitle="in 2024"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Thursday, April 22 2024"
                        post="how-to-create-social-media-captions-with-AI-for-TikTok"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="Social Media Automation"
                        subtitle="Streamline Your Strategy for Better Engagement"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Thursday, April 29 2024"
                        post="social-media-automation-streamline-your-strategy-for-better-engagement"
                    />
                </Grid>
                <Grid item>
                    <MuiXCard
                        title="How to create social media captions with AI for Instagram"
                        subtitle="in 2024"
                        avatarSrc={avatar}
                        author="Joshua Heller"
                        date="Thursday, May 06 2024"
                        post="how-to-create-social-media-captions-with-AI-for-Instagram"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

const MuiXCard = ({ title, subtitle, avatarSrc, author, date, post }) => {
    return (
        <Card sx={{ minWidth: 200, maxWidth: 345, backgroundColor: blue[50], margin: 2 }}>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>

                </Typography>
                <Typography variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography color="textSecondary">
                    {subtitle}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
                    <Avatar src={avatarSrc} />
                    <Typography variant="body2" component="p" style={{ marginLeft: 8 }}>
                        {author}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ marginLeft: 'auto' }}>
                        {date}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button size="small" variant='text'>
                    <Link to={`/blog/${post}`}>Read more</Link>
                </Button>
            </CardActions>
        </Card>
    );
};