import { Box, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import thumbnail from "../../images/thumbnail.png";

export const InAction = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <Box>
            <Typography variant="h4" component="h3" pb={2}>See how it looks in Action 👨🏼‍💻</Typography>
            {(isMobile ?
                <Box>
                    <video width="320" height="222" controls muted style={{ border: '1px solid #8E23A1', borderRadius: '10px' }} poster={thumbnail}>
                        <source src={"https://image-to-caption-permament-uploadstore.s3.eu-central-1.amazonaws.com/ScreenStory_i2c_720.mp4"} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
                :
                <Box >
                    <video width="960" height="668" controls autoPlay muted style={{ border: '1px solid #8E23A1', borderRadius: '10px' }}>
                        <source src={"https://image-to-caption-permament-uploadstore.s3.eu-central-1.amazonaws.com/ScreenStory_i2c_720.mp4"} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            )}
        </Box >
    )
}

