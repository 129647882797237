import { Box, Button, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';

export const RegisterArea = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <Box>
            <Typography variant="h4" component="h3" pb={2}>Register to level up your social media🚀</Typography>
            {(isMobile ?
                <Box pb={2} textAlign={"left"} component="ul" sx={{ listStyleType: 'none' }}>
                    < ListItems />
                </Box>
                :
                <Box pb={2} textAlign={"center"} component="ul" sx={{ listStyleType: 'none' }}>
                    <ListItems />
                </Box>
            )}
            <RegisterButton />
        </Box >
    )
}

const ListItems = () => {

    return (
        <>
            <Typography component="li">✅ 5 credits for free</Typography>
            <Typography component="li">✅ Option to cancel anytime</Typography>
            <Typography component="li">✅ Set your custom brand voice</Typography>
            <Typography component="li">✅ Upload your own photos and videos</Typography>
            <Typography component="li">✅ Trusted by over 170,000 users worldwide</Typography>
        </>
    )
}

export const RegisterButton = () => {

    return (
        <Button size="large" color="secondary" variant='contained' href='https://pro.imagetocaption.ai' component="a" sx={{ mb: 2, p: 2 }}>Get started for free&#160;&#160;
            <LaunchIcon />
        </Button>
    )
}