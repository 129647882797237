import { Box, Grid, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import cyberlab from '../../images/CyberLab.png';
import powerai from '../../images/powerAI.png';

export const KnownFrom = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <Box>
            <Typography variant="h4" component="h1" pb={2}>Known from🤝🏻</Typography>
            {(isMobile ?
                <Box pt={2}>
                    <a href="https://theresanaiforthat.com/ai/imagetocaption-ai/?ref=featured&v=14647" target="_blank" rel="noreferrer"><img width="200" src="https://media.theresanaiforthat.com/featured3.png" alt="Thereisanaiforthat logo"></img></a>
                    <a href="https://www.producthunt.com/posts/imagetocaption-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-imagetocaption&#0045;ai" target="_blank">
                        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=435421&theme=light" alt="imagetocaption&#0046;ai - Transform&#0032;images&#0047;videos&#0032;into&#0032;meaningful&#0032;captions&#0032;instantly&#0033; | Product Hunt" width={200} height={54} />
                    </a>
                    <a href="https://www.capterra.com/p/10018581/imagetocaption-ai/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
                        <img src="https://assets.capterra.com/badge/6bce4442ff5c7a0f8c8236a19d65c1f1.svg?v=2327672&p=379036" width={250} height={65} />
                    </a>
                </Box>
                :
                <Grid container spacing={2} alignItems="center" justifyContent="center" direction="row">
                    <Grid item>
                        <a href="https://theresanaiforthat.com/ai/imagetocaption-ai/?ref=featured&v=14647" target="_blank" rel="noreferrer">
                            <img width="200" src="https://media.theresanaiforthat.com/featured3.png" alt="Thereisanaiforthat logo"></img>
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.producthunt.com/posts/imagetocaption-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-imagetocaption&#0045;ai" target="_blank">
                            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=435421&theme=light" alt="imagetocaption&#0046;ai - Transform&#0032;images&#0047;videos&#0032;into&#0032;meaningful&#0032;captions&#0032;instantly&#0033; | Product Hunt" width={250} height={54} />
                        </a>
                    </Grid>
                    <Grid item>
                        <a href="https://www.capterra.com/p/10018581/imagetocaption-ai/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
                            <img src="https://assets.capterra.com/badge/6bce4442ff5c7a0f8c8236a19d65c1f1.svg?v=2327672&p=379036" width={180} height={54} />
                        </a>
                    </Grid>
                </Grid>
            )}
        </Box >
    )
}

