import { CheckBox, UploadFile, Timelapse, FormatLineSpacing, SupportAgent, School } from "@mui/icons-material";
import { Grid, Paper, Typography, Divider, Box, Stack, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ImageContext } from "../../context/ImageContext";

const ElitePlan = () => {
  const theme = useTheme();
  const { paymentPeriod } = useContext(ImageContext);

  const [billed, setBilled] = useState("Billed monthly");

  const plan = {
    name: 'Elite',
    features: [
      '400 credits/month',
      'Max. video size: 2GB',
      'Max. video length: 3 minutes',
      'Max. 200 characters',
      'priority support',
      'access to knowledge base',
      '+ every additional 200 credits for $50'
    ],
  };

  useEffect(() => {
    if (paymentPeriod === "monthly") {
      setBilled('$100/month');
    } else {
      setBilled('$1200/year');
    }

  }, [paymentPeriod]);

  return (
    <Grid item xs={12} sm={6} md={3.2}>
      <Paper sx={{ padding: 2, mt: '19px' }}>
        <Typography variant="h3" component="h3">
          {plan.name}
        </Typography>
        <Typography variant="h5">
          {billed}
        </Typography>

        <Box pt={2} pb={2}>
          <Divider />
        </Box>

        <Stack direction="row" spacing={1}>
          <CheckBox style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[0]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <UploadFile style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[1]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Timelapse style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[2]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <School style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[5]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <SupportAgent style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[4]}
          </Typography>
        </Stack>

      </Paper>
    </Grid>
  );
}

export default ElitePlan;