import { useEffect } from 'react';
import { Box } from '@mui/material';

const HubSpotSignUpForm = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        script.async = true;
        script.charset = 'utf-8';

        // Append script to the body
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                // Initialize the form
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "143597048",
                    formId: "d35aa161-3968-4933-beac-9b9a23dd787d",
                    target: '#newsletterForm'
                });
            }
        });

        // Clean up
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Box id="newsletterForm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        </Box >
    )
};

export default HubSpotSignUpForm;
