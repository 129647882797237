import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material"
import { ImageContext } from "../../../context/ImageContext";
import { useContext, useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const Content = () => {
    const { responseCaption, responseLoading } = useContext(ImageContext);
    const [captionValue, setCaptionValue] = useState<string>("");
    const [editDisabled, setEditDisabled] = useState<boolean>(true);

    useEffect(() => {
        responseCaption === "" ? setEditDisabled(true) : setEditDisabled(false);
        setCaptionValue(responseCaption);
    }, [responseCaption, responseLoading]);

    const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        setCaptionValue(event.target.value);
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(captionValue);
    }

    return (
        <Box textAlign="center" >
            <Typography variant='h4' component={"p"} mt={4} mb={2}>Caption: </Typography>
            <TextField disabled={editDisabled} value={captionValue} onChange={changeValue} fullWidth label="caption" variant="outlined" multiline />
            <Box mt={2}>
                {responseLoading === true ? <CircularProgress /> : <></>}
            </Box>
        </Box >
    )
}

