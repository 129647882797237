import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import { CustomTheme } from '../../theme/theme';
import { FreeTrialArea } from './freeTrialArea';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';

const BlogPost = () => {
  const [postContent, setPostContent] = useState('');
  const { postName } = useParams();
  const theme = CustomTheme.getTheme();

  useEffect(() => {
    import(`./posts/${postName}.md`)
      .then(async m => {
        let url = m.default;

        // const response = await fetch(process.env.PUBLIC_URL + '/posts/' + postName + '.md');
        const response = await fetch(url);

        const text = await response.text();

        setPostContent(marked(text));
      })
      .catch(err => {
        console.error(err);
        // handle error
      });
  }, [postName]);

  return (
    <>
      <Breadcrumbs>
        <Link color="inherit" href="/">
          Home
        </Link>
        <Link color="inherit" href="/blog">
          Blog
        </Link>
        <Typography color="textPrimary">{postName}</Typography>
      </Breadcrumbs>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{
          fontFamily: theme.typography.fontFamily,
          color: theme.palette.text.primary,
          fontSize: theme.typography.body1.fontSize,
          textAlign: 'left',
          maxWidth: '800px',
        }}
          dangerouslySetInnerHTML={{
            __html: `
      <style>
        p {
          line-height: 1.6;
        }
      </style>
      ${postContent}
    `
          }}

        />
      </div>
      <FreeTrialArea />
    </>
  );
};

export default BlogPost;
