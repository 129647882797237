import { useContext } from 'react';
import { Alert, Button, Link } from '@mui/material';
import { Box } from '@mui/material';
import { ImageContext, ImageDispatchContext } from '../../../context/ImageContext';
import { useNavigate } from 'react-router-dom';

export const NotificationBar = () => {
    const { notificationBarHidden } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);
    const navigate = useNavigate();

    const style = {
        position: 'sticky',
        top: 0,
        zIndex: 10000,
        width: '100%',
        padding: '10px',
        boxSizing: 'border-box',
    };

    const isMobile = window.innerWidth <= 600; // Adjust the breakpoint value as needed

    const handleClose = () => {
        dispatch({ type: 'CHANGE_NOTIFICATIONBAR_HIDDEN', notificationBarHidden: true });
    };

    return (
        <Box sx={style}>
            {/* desktop: */}
            {isMobile ? null :
                <Box display={notificationBarHidden == false ? "inline-block" : "none"} sx={{ width: '100%' }}>
                    <Alert onClose={handleClose} severity="info" sx={{ maxWidth: '100%', fontSize: isMobile ? '12px' : 'inherit' }}>
                        We now have the option to add your own <strong>brand voice</strong> in your Settings. This makes the captions a lot more authenic and easier to generate.
                        <br></br>
                        <a href='https://pro.imagetocaption.ai'>Get started</a> for free and try it out yourself.
                    </Alert>
                </Box>
            }
            {/* mobile: */}
            {isMobile ?
                <Box display={notificationBarHidden == false ? "inline-block" : "none"} sx={{ width: '100%' }}>
                    <Alert onClose={handleClose} severity="info" sx={{ maxWidth: '100%', fontSize: isMobile ? '12px' : 'inherit' }}>
                        We now have the option to add your own <strong>brand voice</strong> in your Settings. This makes the captions a lot more authenic and easier to generate.
                        <br></br>
                        <Button onClick={() => navigate('https://pro.imagetocaption.ai')} sx={{ mt: '16px', mb: '8px' }} variant='text'>Get started </Button>for free and to try it out.
                    </Alert>
                </Box>
                : null}
        </Box>
    );
};