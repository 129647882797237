import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ImageContext, ImageDispatchContext } from "../../../context/ImageContext";
import { useCookies } from "react-cookie";
import LaunchIcon from '@mui/icons-material/Launch';

const SubmitButton = (props: { image: string }) => {
    const { imageUrl, imageName, bucketName, getCaptionButtonDisabled, imageUploaded, responseCaption, language, theme, setting, targetaudience, mood, formofadress, custom, emojis, hashtags, cta, length } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);

    const [cookies, setCookie] = useCookies(['sbmCntD', 'sbmCntW']);

    const [tryAgaintime, setTryAgaintime] = useState("");

    useEffect(() => {
        if (cookies.sbmCntW > 10) {
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });
            setTryAgaintime("Limit reached! Register to continue");
        } else if (cookies.sbmCntD > 5) {
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });
            setTryAgaintime("Limit reached! Register to continue");
        }
    }
        , [cookies, responseCaption]);

    const getCaption = () => {
        dispatch({ type: "CHANGE_CAPTION", responseCaption: "Caption loading..." });
        dispatch({ type: "CHANGE_LOADING", responseLoading: true });
        dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });

        let apibaseUrl = "https://d12fcqm38bob0a.cloudfront.net"

        console.log(language);

        axios.post(apibaseUrl, {
            url: imageUrl,
            name: imageName,
            bucket: bucketName,
            language: language,
            theme: theme,
            setting: setting,
            targetaudience: targetaudience,
            mood: mood,
            formofadress: formofadress,
            custom: custom,
            emojis: emojis,
            hashtags: hashtags,
            cta: cta,
            image: props.image,
            length: length
        }).then(async (response) => {
            setTimeout(() => {
                dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
                dispatch({ type: "CHANGE_LOADING", responseLoading: false });
                dispatch({ type: "CHANGE_CAPTION", responseCaption: response.data });
            }, 1);
        }).catch((error) => {
            console.log(error);
            dispatch({ type: "CHANGE_CAPTION", responseCaption: "Error: Service temporarily unavailable. " + "Consider registering for free to get access to the dedicated pro infrastructure" });
            dispatch({ type: "CHANGE_LOADING", responseLoading: false });
            console.log(error);
        });

        const uplCnt = parseInt(cookies.sbmCntD || 0);
        const oneDay = 24 * 60 * 60 * 1000; // One month in milliseconds
        setCookie('sbmCntD', (uplCnt) + 1, { path: '/', expires: new Date(Date.now() + oneDay) });
        const oneWeek = 7 * oneDay; // One month in milliseconds
        const uplCntW = parseInt(cookies.sbmCntW || 0);
        setCookie('sbmCntW', (uplCntW) + 1, { path: '/', expires: new Date(Date.now() + oneWeek) });
    }

    return (
        <Stack direction="column" spacing={4}>
            <Box>
                <Button disabled={getCaptionButtonDisabled} variant="contained" component="label" onClick={getCaption} >{imageUploaded === true && responseCaption !== "" ? "Regenerate Caption" : "Generate Caption"}</Button>
            </Box>
            <Box display={tryAgaintime == "" ? "none" : "inline-block"}>
                <Typography variant="body1" color="red" mt={1} textAlign={"center"}>{tryAgaintime}</Typography>
                <Button size="large" color="secondary" variant='contained' href='https://pro.imagetocaption.ai' component="a" sx={{ mb: 2, p: 2 }}>Start free trial&#160;&#160;
                    <LaunchIcon />
                </Button>
            </Box>
        </Stack >
    );
}

export default SubmitButton;