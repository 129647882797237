import { Avatar, Box, Card, CardContent, CardMedia, Grid, Rating, Stack, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import leenor from './../../images/leenor.jpg';
import hermann from './../../images/hermann.jpg';
import jignush from './../../images/jignush.jpg';

export const Testimonials = () => {

    return (
        <Box>
            <Typography variant="h4" component="h3" pb={4} pt={2}>Hear what our users say about us🥰</Typography>

            <Grid container spacing={4} alignItems="center" justifyContent="center" direction="row">
                <Grid item>
                    <TestimonialCard imageSrc={leenor} name={"LeeNor Dikel"} text={'"Incredible Tool, saved me so much time 🙌🏻🔥"'} rating={5} />
                </Grid>
                <Grid item>
                    <TestimonialCard imageSrc={hermann} name={"Hermann Litau"} text={'"It helps us generate captions for clients in our social media agency, making our day-to-day work a lot easier!"'} rating={5} />
                </Grid>
                <Grid item>
                    <TestimonialCard imageSrc={jignush} name={"Jignush Santosh"} text={'"It helps me to make good captions🤩"'} rating={4.5} />
                </Grid>

            </Grid>

        </Box >
    )
}

const TestimonialCard = ({ imageSrc, text, rating, name }) => (
    <Card style={{ width: 300, height: 210 }}>
        <CardContent>
            <Stack direction="column" spacing={1} alignItems={"center"}>
                <Avatar src={imageSrc} />
                <Typography variant="h6" component="h4">{name}</Typography>
                <Rating value={rating} precision={0.5} readOnly />
                <Typography variant="caption" component="p">{text}</Typography>
            </Stack>
        </CardContent>
    </Card>
);