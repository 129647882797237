import React, { useContext, useState } from 'react';
import { FormControl, Box, InputLabel, Select, MenuItem, useTheme, useMediaQuery, Stack } from '@mui/material';
import { ImageContext, ImageDispatchContext } from '../../../context/ImageContext';
import { ParameterCheckbox } from './parameterCheckbox';
import { ParameterField } from './parameterField';

const CaptionOptions = () => {
    const { language, theme, setting, targetaudience, mood, formofadress, plattform, custom, emojis, hashtags, cta } = useContext(ImageContext);
    const dispatch = useContext(ImageDispatchContext);

    const reactTheme = useTheme();
    const isMobile = useMediaQuery(reactTheme.breakpoints.down('sm'));

    const handleLanguageChange = (event: { target: { value: any; }; }) => {
        // alert('register to get access to all 10+ languages')
        dispatch({ type: 'CHANGE_LANGUAGE', language: event.target.value });
    };

    const handlePlattformChange = (event: { target: { value: any; }; }) => {
        dispatch({ type: 'CHANGE_PLATTFORM', plattform: event.target.value });
        // alert('register to get access to all all plattforms')
    };

    const handleThemeChange = (value: string) => {
        dispatch({ type: 'CHANGE_THEME', theme: value });
    };

    const handleSettingChange = (value: string) => {
        dispatch({ type: 'CHANGE_SETTING', setting: value });
    };

    const handleTargetAudienceChange = (value: string) => {
        dispatch({ type: 'CHANGE_TARGETAUDIENCE', targetaudience: value });
    };

    const handleMoodChange = (value: string) => {
        dispatch({ type: 'CHANGE_MOOD', mood: value });
    };

    const handleFormOfAdressChange = (value: string) => {
        dispatch({ type: 'CHANGE_FORMOFADRESS', formofadress: value });
    };

    const handleCustomChange = (value: string) => {
        dispatch({ type: 'CHANGE_CUSTOM', custom: value });
    };

    const handleEmojisChange = (event: React.ChangeEvent<{}>, value: string) => {
        let newValue = null;
        value === "true" ? newValue = true : newValue = false;
        dispatch({ type: 'CHANGE_EMOJIS', emojis: newValue });
    };

    const handleHashtagsChange = (event: React.ChangeEvent<{}>, value: string) => {
        let newValue = null;
        value === "true" ? newValue = true : newValue = false;
        dispatch({ type: 'CHANGE_HASHTAGS', hashtags: newValue });
    };

    const handleCtaChange = (event: React.ChangeEvent<{}>, value: string) => {
        let newValue = null;
        value === "true" ? newValue = true : newValue = false;
        dispatch({ type: 'CHANGE_CTA', cta: newValue });
    };

    const [paramsDisabled, setParamsDisabled] = React.useState(false);

    return (
        <>
            <FormControl sx={{ pb: 4, minWidth: 150 }}>
                <InputLabel>Language</InputLabel>
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                    label="Language"
                >
                    <MenuItem value="en">🇬🇧 English</MenuItem>
                    <MenuItem value="de">🇩🇪 German</MenuItem>
                    <MenuItem value="fr">🇫🇷 French</MenuItem>
                    <MenuItem value="es">🇪🇸 Spanish</MenuItem>
                    <MenuItem value="ar">🇦🇪 Arabic</MenuItem>
                    <MenuItem value="ch">🇨🇳 Chinese</MenuItem>
                    <MenuItem value="ko">🇰🇷 Korean</MenuItem>
                    <MenuItem value="ja">🇯🇵 Japanese</MenuItem>
                    <MenuItem value="ru">🇷🇺 Russian</MenuItem>
                    <MenuItem value="pr">🇵🇹 Portugese</MenuItem>
                    <MenuItem value="pl">🇵🇱 Polish</MenuItem>
                    <MenuItem value="al">🇦🇱 Albanian</MenuItem>
                    <MenuItem value="bu">🇧🇬 Bulgarian</MenuItem>
                    <MenuItem value="cr">🇭🇷 Croatian</MenuItem>
                    <MenuItem value="cz">🇨🇿 Czech</MenuItem>
                    <MenuItem value="da">🇩🇰 Danish</MenuItem>
                    <MenuItem value="du">🇳🇱 Dutch</MenuItem>
                    <MenuItem value="fi">🇫🇮 Finnish</MenuItem>
                    <MenuItem value="sw">🇸🇪 Swedish</MenuItem>
                    <MenuItem value="gr">🇬🇷 Greek</MenuItem>
                    <MenuItem value="hu">🇭🇺 Hungarian</MenuItem>
                    <MenuItem value="ro">🇷🇴 Romanian</MenuItem>
                    <MenuItem value="tu">🇹🇷 Turkish</MenuItem>
                    <MenuItem value="hi">🇮🇳 Hindi</MenuItem>
                    <MenuItem value="be">🇮🇳 Bengali</MenuItem>
                    <MenuItem value="te">🇮🇳 Telugu</MenuItem>
                    <MenuItem value="ma">🇮🇳 Marathi</MenuItem>
                    <MenuItem value="ta">🇮🇳 Tamil</MenuItem>
                    <MenuItem value="ur">🇮🇳 Urdu</MenuItem>
                    <MenuItem value="ka">🇮🇳 Kannada</MenuItem>
                    <MenuItem value="od">🇮🇳 Odia</MenuItem>
                </Select>
            </FormControl>

            <Box>
                <Stack direction="row" justifyContent={'center'}>
                    <Box justifyContent="center" display="flex">
                        <FormControl disabled={paramsDisabled} sx={{ pb: 4, minWidth: 250, display: 'flex', justifyContent: 'center', textAlign: 'left' }}>
                            <InputLabel>Plattform</InputLabel>
                            <Select
                                value={plattform}
                                label="Plattform"
                                onChange={handlePlattformChange}
                            >
                                <MenuItem value="Instagram">Instagram</MenuItem>
                                <MenuItem value="Facebook">Facebook</MenuItem>
                                <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                                <MenuItem value="Pinterest">Pinterest</MenuItem>
                                <MenuItem value="Twitter">X (Twitter)</MenuItem>
                                <MenuItem value="TikTok">TikTok</MenuItem>
                                <MenuItem value="Social Media">Social Media</MenuItem>
                                <MenuItem value="Online Shop">Online Shop</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Stack >
                {
                    isMobile ?
                        <>
                            <Stack direction="column" spacing={1}>
                                <ParameterField options={['advertisment', 'holiday', 'wedding', 'product description']} value={theme} handleParamChange={handleThemeChange} label={"Theme"} title={"Help the AI understand what your post is about by selecting or explaining the theme/topic."} />
                                <ParameterField options={['outdoor', 'indoor', 'office', 'city', 'beach']} value={setting} handleParamChange={handleSettingChange} label={"Setting/location"} title={"Choose the location of the photo. Specific locations like cities or name of places are also possible. For example: Time square New York, "} />
                                <ParameterField options={['friendly', 'luxury', 'relaxed', 'professional', 'bold', 'adventurous', 'nerdy']} value={mood} handleParamChange={handleMoodChange} label={"Tone"} title={"The formulation of the caption"} />
                                <ParameterField options={[]} value={custom} handleParamChange={handleCustomChange} label={"Custom"} title={"Add your custom prompt here. Whatever information is not covered by the other parameters and is not automatically detected by the AI. For example: the name of the person on the photo, specific words or brand names to be used"} />
                            </Stack>
                        </>
                        :
                        <>
                            <Stack direction="row" spacing={2} justifyContent={'center'}>
                                <ParameterField options={['advertisment', 'holiday', 'wedding', 'product description']} value={theme} handleParamChange={handleThemeChange} label={"Theme"} title={"Help the AI understand what your post is about by selecting or explaining the theme/topic."} />
                                <ParameterField options={['outdoor', 'indoor', 'office', 'city', 'beach']} value={setting} handleParamChange={handleSettingChange} label={"Setting/location"} title={"Choose the location of the photo. Specific locations like cities or name of places are also possible. For example: Time square New York, "} />
                            </Stack>

                            <Stack direction="row" spacing={2} justifyContent={'center'}>

                                <ParameterField options={['friendly', 'luxury', 'relaxed', 'professional', 'bold', 'adventurous', 'nerdy']} value={mood} handleParamChange={handleMoodChange} label={"Tone"} title={"The formulation of the caption"} />
                                <ParameterField options={[]} value={custom} handleParamChange={handleCustomChange} label={"Custom"} title={"Add your custom prompt here. Whatever information is not covered by the other parameters and is not automatically detected by the AI. For example: the name of the person on the photo, specific words or brand names to be used"} />
                            </Stack>
                        </>
                }

                {/* <Stack direction="column" spacing={2}>
                    <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Emojis"} value={emojis} handleCheckboxChange={handleEmojisChange} />
                    <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Hashtags"} value={hashtags} handleCheckboxChange={handleHashtagsChange} />
                    <ParameterCheckbox paramsDisabled={paramsDisabled} label={"Call-to-Action"} value={cta} handleCheckboxChange={handleCtaChange} />
                </Stack> */}
            </Box >
        </>
    );
};

export default CaptionOptions;
