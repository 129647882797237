import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

const customTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#35d0ba',
            light: '#5edac8',
            dark: '#28aa98',
            contrastText: '#2a2a2a',
        },
        secondary: {
            main: '#9c27b0',
        },
    },
    typography: {
        fontFamily: 'Rubik',
    },
    shape: {
        borderRadius: 10,
    },
};


const WHTCTheme = createTheme(customTheme)


export const CustomTheme = {
    getTheme: (): Theme => {
        return WHTCTheme;
    }
}