import { Content } from "./components/content";
import SubmitButton from "./components/SubmitButton";
import UploadImage from "./components/UploadImage";
import CaptionOptions from "./components/captionOptions";
import { Box, Typography } from "@mui/material";
import GeneratedWithAI from "./components/generatedWithAI";

const Demo = () => {

    return (
        <>
            <Typography variant="h4" component="h3" pb={2}>Try it!</Typography>

            <UploadImage photo="online-shop" />

            <CaptionOptions />
            <SubmitButton image="online-shop" />
            <Content />
            
            <Typography variant="h5" component="h4" pb={1} pt={2}>Also try out Demos for:</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <GeneratedWithAI />
            </Box>
        </>
    );
}

export default Demo;