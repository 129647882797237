import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PricingPlans from "../../pricingPlans";

export const Faq = () => {

    return (
        <Box>
            <Typography variant="h4" pb={4} pt={2}>Frequently Asked Questions</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>What is imagetocaption AI?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        imagetocaption.ai is an AI-powered tool that generates captions for your images and videos. It's the perfect solution for anyone who needs to create captions for their posts, whether it's for social media, shopify descriptions, Instagram captions, TikTok, or any other platform. Our caption generator uses modern AI technology to craft captions that resonate with your audience, ensuring your visuals are always accompanied by the perfect caption.
                        <br /><br />
                        You can use it to create a picture caption for instagram, a caption for Facebook, or any other platform. It's the ultimate image caption online tool. Plus, it's incredibly easy to use, making it the go-to solution for all your caption needs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>How does imagetocaption.ai work?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        It's incredibly easy to use image to caption AI! Start by uploading your chosen image or video, and then select the target platform - be it Instagram, TikTok, an online shop, or Facebook. Next, choose the language for your caption, ensuring it aligns perfectly with your audience.
                        <br /><br />
                        Then, dive into customizing your caption to fit your specific needs. This includes setting the theme, pinpointing the location of the photo, selecting the tone and adding any additional custom information. These details help craft a copy that resonates with your audience.
                        <br /><br />
                        You also have the option to include hashtags, emojis, and a call-to-action, making it a comprehensive caption maker for all your needs. Plus, you can adjust the length of the output, ensuring our caption generator from photo delivers exactly what you need.
                        <br /><br />
                        Finally, hit 'Create Caption' and in just a few seconds, a fitting caption will be generated for you. If it's not quite right, you can easily tweak the parameters and use our sentences rewriter to generate a new caption. This level of customization and ease-of-use makes imagetocaption your go-to solution for all your picture caption needs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>Will the text sound authentic?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        We offer the option to add your own brand voice details. This makes it easier and faster for you to create captions, while also making them more relevant and fitting to your brand voice. With just some basic information about you or your company, which you can copy from your website or social media, extremely authentic social media texts are possible.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>How much does it cost?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"} pb={2}>
                        You can use it for free! 5 credits per month are on us. We want everyone to be able to leverage the power of AI for their social media!
                    </Typography>
                    <PricingPlans />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>Can I upload videos?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        Yes! You also generate captions for your videos. The video needs to be .mp4 file.
                        <br /><br />
                        Basic plan: you can upload videos up to 500MB and 1min length.
                        <br />
                        Plus plan & Elite plan: you can upload videos up to 2GB and 3min length.
                        <br /><br />
                        If you need to upload bigger or longer videos please reach out to us at info@imagetocaption.ai.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>How can I make captions for my Instagram Reel or TikTok?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        imagetocaption.ai is perfect for creating captions for your Instagram Reel or TikTok.
                        <br /><br />
                        Steps to create a caption directly from your Instagram or TikTok:
                        <br /><br />
                        1. Create a draft of your Reel or TikTok video in the Instagram App or TikTok App.
                        <br />
                        2. Download the draft video to your phone.
                        <br />
                        3. Upload the video from your photo library to imagetocaption.ai.
                        <br />
                        4. Select Instagram/TikTok as target plattform and add optionaly add parameters to individualize your result.
                        <br />
                        5. Click on 'Generate caption'.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>What if the caption doesn't fit?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        You can regenerate the caption as often as you want. Every time a slightly different caption is generated. You can also influence the creativity of the caption.
                        Therefore chances are basically zero that you won’t get a caption which fits to your preferences.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>How many credits does a caption cost?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        Images: 1 credit / caption
                        <br /><br />
                        Videos: Each video has a minimum cost of 3 credits. If the video is longer than 30 seconds you will be charged 1 additional credit for every additional 30 seconds. Regeneration of a caption (e.g. with different parameters) for a video only costs 1 credit.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>Which languages are supported?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        Currently we support English, German, French, Spanish, Arabic, Chinese, Korean, Japanese, Russian, Portugese, Polish, Albanian, Bulgarian, Croatian, Czech, Danish, Dutch, Finnish, Swedish, Greek, Hungarian, Romanian, Turkish.
                        Also we support the following Indian languages: Hindi, Bengali, Telugu, Marathi, Tamil. Urdu, Kannada, Odia. If you need another language please reach out to us at info@imagetocaption.ai
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>What is the best caption generator?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        image to caption AI is the best caption generator. It is the only caption generator that offers so many options to individualize your caption. You can choose the language, the theme, the wording and style, even add custom information, choose emojis, hashtags and a call to action. Also you can set the length of the caption. You can upload your own photo or video. And you can regenerate the caption as often as you want. Every time a unique caption is generated. We use chatGPT AI technology to generate a caption that fits your needs perfectly.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box >
    )
}