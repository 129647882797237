import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import Headline from './headline';
import HubSpotSignUpForm from '../about-us/components/HubSpotSignUpForm';
import { CompanyTestimonials } from '../tool/components/companyTestimonials';
import { Faq } from '../tool/components/faq';
import GeneratedWithAI from '../tool/components/generatedWithAI';
import { KnownFrom } from '../tool/components/knownFrom';
import { RegisterArea } from '../tool/components/registerArea';
import { SoFar } from '../tool/components/soFar';
import { Testimonials } from '../tool/components/testimonials';

const MainPage: React.FC = () => {
    return (
        <Box pb={4}>
            <Headline />

            <RegisterArea />

            <Box pb={8} pt={8} >
                <Divider />
            </Box>

            <SoFar />

            <Box pb={8} pt={8} >
                <Divider />
            </Box>

            <Testimonials />

            <Box pb={8} pt={8} >
                <Divider />
            </Box>

            <RegisterArea />

            <Box pb={8} pt={8} >
                <Divider />
            </Box>

            {/* <ValueCalculator /> */}

            <KnownFrom />

            <Box pb={8} pt={8} >
                <Divider />
            </Box>

            <Faq />


            <Box pb={8} pt={8} >
                <Divider />
            </Box>

            <Box>
                <Typography variant="h4" pb={4} pt={2}>Sign up to our newsletter:</Typography>
                <HubSpotSignUpForm />
            </Box>
        </Box >
    );
};

export default MainPage;
