import { Box, Button, Divider, Grid, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import logo from './../../images/imagetocaptionai.svg';

export const TopNav = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <nav>
            <Box>
                {(isMobile ?
                    <Box sx={{ flexGrow: 1 }}>
                        <Toolbar>
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <a href="/" ><img style={{ width: isMobile ? '90%' : '30%', margin: 'auto' }} src={logo} alt="imagetocaption logo"></img></a>
                                </Grid>
                                <Grid item>
                                    <Grid item>
                                        <Divider />
                                        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                                            <a href="https://pro.imagetocaption.ai"><Button sx={{ height: '40px' }} variant='text' color='primary'>Log in / Sign up</Button></a>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </Box>
                    :
                    <Box>
                        <Box sx={{ flexGrow: 1, display: 'flex', ml: 4, mr: 4, mb: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ justifyContent: 'flex-start' }}>
                                <a href="/" ><img style={{ width: isMobile ? '90%' : '30%', margin: 'auto' }} src={logo} alt="imagetocaption logo"></img></a>
                            </Box>
                            <Box alignSelf={"center"}>
                                <a href="https://pro.imagetocaption.ai"><Button sx={{ height: '40px', width: '160px', alignSelf: 'center' }} variant='text' color='primary'>Log in / Sign up</Button></a>
                            </Box>
                        </Box>
                        <Divider />
                    </Box>
                )}
            </Box>
        </nav>
    );
}