import { Box, Button, Divider, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';

export const FreeTrialArea = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <Box mt={8}>
            <Box pb={8}>
                <Divider />
            </Box>
            <Typography variant="h4" component="h3" pb={2}>Are you a busy social media manager?</Typography>

            {(isMobile ?
                <>
                    <Typography textAlign={"left"}>
                        imagetocaption.ai is an AI-powered tool that generates social media captions for your images and videos. It's the perfect solution for anyone who needs to create compelling and engaging texts for their social media posts and save hours of time every week.
                        <br></br><br></br>
                    </Typography>
                    <Box pb={1} textAlign={"left"} component="ul" sx={{ listStyleType: 'none' }}>
                        < ListItems />
                    </Box>

                </>
                :
                <>
                    <Typography textAlign={"center"}>
                        imagetocaption.ai is an AI-powered tool that generates social media captions for your images and videos. It's the perfect solution for anyone who needs to create compelling and engaging texts for their social media posts and save hours of time every week.
                        <br></br><br></br>
                    </Typography>
                    <Box pb={1} textAlign={"center"} component="ul" sx={{ listStyleType: 'none' }}>
                        <ListItems />
                    </Box>

                </>
            )}
            <Button size="large" color="secondary" variant='contained' href='https://pro.imagetocaption.ai' component="a" sx={{ mb: 2, p: 2 }}>Start free trial&#160;&#160;
                <LaunchIcon />
            </Button>
        </Box >
    )
}

const ListItems = () => {

    return (
        <>
            <Typography component="li">✅ Free trial for 3 days</Typography>
            <Typography component="li">✅ Option to cancel anytime</Typography>
            <Typography component="li">✅ Upload your own photos and videos</Typography>
            <Typography component="li">✅ Trusted by thousands of users worldwide</Typography>
        </>
    )
}