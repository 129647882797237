import { Card, CardMedia } from "@mui/material";

export const ImageToCaptionCard = ({ imageSrc }) => (
    <Card style={{ maxWidth: 345 }}>
        <CardMedia
            component="img"
            alt="Generated caption"
            height={'600px'}
            image={imageSrc}
            title="Generated caption"
        />
    </Card>
);