import { Button, Grid, Stack, Typography, Box } from "@mui/material";
import PlusPlan from "./plusPlan";
import SubscriptionSwitch from "./subscriptionSwitch";
import BasicPlan from "./basicPlan";
import ElitePlan from "./elitePlan";
import { useEffect, useState } from "react";
import FreePlan from "./freePlan";

const PricingPlans = () => {
  const [showForm, setShowForm] = useState('none');

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.charset = 'utf-8';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        // Initialize the form
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "143597048",
          formId: "563916f3-695b-458e-9f65-889f53c70350",
          target: '#enterpriseContactForm' // Targeting a specific div to render the form
        });
      }
    });

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleOpen = (event) => {
    event.preventDefault();
    setShowForm('flex')
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <SubscriptionSwitch />
        <Grid container spacing={1} justifyContent="center">
          <FreePlan />
          <BasicPlan />
          <PlusPlan />
          <ElitePlan />
        </Grid>
      </Stack>
      <Button sx={{ marginTop: 2 }} size="large" color="secondary" variant='contained' href='https://pro.imagetocaption.ai'>Purchase now</Button>

      <Box mt={4}>
        <Typography textAlign={"left"} >
          <Typography variant="h6" component="span">Enterprise customers: </Typography><a href="#" onClick={handleOpen} style={{ color: '#35d0ba' }}>Contact us</a> for an individual offer.</Typography>

        <Box pt={2} id="enterpriseContactForm" display={showForm} style={{ justifyContent: 'center', alignItems: 'center' }} ></Box >
      </Box>
    </>
  );

}

export default PricingPlans;