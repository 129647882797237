import Box from '@mui/material/Box';
import { TopNav } from './components/topnav';

export function Header() {

  return (
    <Box>
      <TopNav />
    </Box>
  );
}