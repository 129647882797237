import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import example1 from '../images/IMG_6617.jpg';
import example2 from '../images/IMG_6604.jpg';
import example3 from '../images/IMG_6603.jpg';
import example4 from '../images/IMG_6623.jpg';
import LaunchIcon from '@mui/icons-material/Launch';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { InAction } from '../tool/components/inAction';
import { CompanyTestimonials } from '../tool/components/companyTestimonials';

const Headline = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box pb={2}>
            <header>
                {(isMobile ?
                    <Box pb={4}>
                        <Typography variant="h3" component="h1" pb={2} textAlign={"center"}>AI Caption generator for busy social media managers</Typography>
                        <Typography variant="h5" component="h2" pb={2} textAlign={"center"}>Are you sick of writing social media texts?😫</Typography>

                    </Box> :
                    <Box pb={4}>
                        <Grid container spacing={2} pb={0} alignItems={"center"}>
                            <Grid item xs={3} >
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h3" component="h1" pb={2} textAlign={"center"}>AI Caption generator for busy social media managers</Typography>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" component="h2">Are you sick of writing social media texts?😫</Typography>
                    </Box>
                )}

                <Button size="large" color="secondary" variant='contained' href='https://pro.imagetocaption.ai' component="a" sx={{ mb: 2, p: 2 }}>Get started for free&#160;&#160;
                    <LaunchIcon />
                </Button>

                <Stats />

                <InAction />

                <CompanyTestimonials />

                <Box pb={2} pt={4} >
                    <Divider />
                </Box>

                <Perks />

                <Box pb={8} pt={8} >
                    <Divider />
                </Box>
            </header>

        </Box>
    );
}

const Stats = () => {
    return (
        <>
            <Typography variant="caption" component="h4" pb={4}>
                <Box component="span" display="inline-flex" alignItems="center">
                    <FavoriteIcon color='error' />
                    &#160;by 170K+ happy users!
                </Box>
            </Typography>
        </>
    )
}


const Perks = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box mb={2}>
            <Box>
                <Typography variant="h4" component="h4" pb={2} pt={4} color={"black"}>What do you get when you use the No.1 AI caption generator?🤔</Typography>
            </Box>
            {isMobile ?
                <Box>
                    <PerksAccordion />

                    <Grid container spacing={0} alignItems="center" justifyContent="center" direction="column" pt={2}>
                        <Grid item>
                            <img width={"280px"} src={example3} alt="example caption"></img>
                        </Grid>
                        <Grid item>
                            <img width={"280px"} src={example2} alt="example caption"></img>
                        </Grid>
                    </Grid>
                </Box>
                :
                <Grid container spacing={2} pb={2} pt={4} alignItems={"center"}>
                    <Grid item xs={5} >
                        <PerksAccordion />
                    </Grid>
                    <Grid item xs={7} >
                        <Grid container spacing={0} alignItems="center" justifyContent="center" direction="row">
                            <Grid item>
                                <img width={"250px"} src={example3} alt="example caption"></img>
                            </Grid>
                            <Grid item>
                                <img width={"250px"} src={example2} alt="example caption"></img>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

        </Box>
    )
}

export const PerksAccordion = () => {

    return (
        <Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>💬 Speak in you brand's voice</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"} pb={2}>
                        Forget other AI generators like chatGPT. With imagetocaption.ai you can set your own brand voice and tonality and give additional context for your company or brand. This makes your captions hyper-relevant to your unique brand voice and style.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>🌟 Greater reach</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        Going viral is hard. I takes consistency, good content, keywords and hashtags. We help you grow on social media by letting you focus on the content. Don’t worry, we take care of the rest. Transform your visuals into compelling stories!                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>🏖 More free time</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        Our users saved thousands of hours so far! That means you can focus on what really matters: producing your content or products. Or you take a nap instead. No matter what, we want to give back your time so you can have a more fulfilled life.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box >
    )
}

export default Headline;