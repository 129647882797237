import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ImageContext } from "../../../context/ImageContext";
import { useCookies } from "react-cookie";
import leenor from '../../images/leenor1.jpg';
import grace from '../../images/grace.jpg';
import onlineShop from '../../images/landing-pages/online-shop.jpg';
import arabic from '../../images/landing-pages/arabic.jpg';
import businessDE from '../../images/landing-pages/business_de.jpg';
import business from '../../images/landing-pages/business.jpg';
import fashion from '../../images/landing-pages/fashion.jpg';
import photography from '../../images/landing-pages/photography.jpg';
import insta from '../../images/landing-pages/insta.jpg';
import india from '../../images/landing-pages/india.jpg';

const UploadImage = (props: { photo: string }) => {
    const { imageUploaded, imageUploading } = useContext(ImageContext);

    const [cookies, setCookie] = useCookies(['uplCntD', 'uplCntW']);

    const [uploadDisabled, setUploadDisabled] = useState(false);

    const [tryAgaintime, setTryAgaintime] = useState("");

    useEffect(() => {
        if (cookies.uplCntW > 7) {
            setUploadDisabled(true);
            setTryAgaintime("7 days");
        } else if (cookies.uplCntD > 3) {
            setUploadDisabled(true);
            setTryAgaintime("24 hours");
        }

    }
        , [cookies, imageUploaded, imageUploading]);

    const selectedPhoto = () => {
        switch (props.photo) {
            case "leenor":
                return leenor;
            case "grace":
                return grace;
            case "online-shop":
                return onlineShop;
            case "arabic":
                return arabic;
            case "business_de":
                return businessDE;
            case "business":
                return business;
            case "fashion":
                return fashion;
            case "photography":
                return photography;
            case "insta":
                return insta;
            case "india":
                return india;
            default:
                return leenor;
        }
    }

    return (
        <Box mb={4}>
            <Box component="img" sx={{ height: 300 }} alt={props.photo} src={selectedPhoto()} />
        </Box>
    );
};

export default UploadImage;