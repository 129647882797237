import { Content } from "./../../tool/components/content";
import SubmitButton from "./../../tool/components/SubmitButton";
import UploadImage from "./../../tool/components/UploadImage";
import CaptionOptions from "./../../tool/components/captionOptions";
import { Box, Typography } from "@mui/material";
import { RegisterButton } from "./../../tool/components/registerArea";
import { useContext, useEffect } from "react";
import { ImageDispatchContext } from "../../../context/ImageContext";
import GeneratedWithAI from "../../tool/components/generatedWithAI";

const India = () => {
    const dispatch = useContext(ImageDispatchContext);

    useEffect(() => {
        dispatch({ type: "CHANGE_FILENAME", imageName: "india.jpg" });
        dispatch({ type: "CHANGE_PLATTFORM", plattform: "Instagram" });
        dispatch({ type: "CHANGE_LANGUAGE", language: "hi" })
        dispatch({ type: "CHANGE_THEME", theme: "" });
        dispatch({ type: "CHANGE_SETTING", setting: "Mumbai" });
        dispatch({ type: "CHANGE_TARGETAUDIENCE", targetaudience: "" });
        dispatch({ type: "CHANGE_MOOD", mood: "romantic" });
        dispatch({ type: "CHANGE_FORMOFADRESS", formofadress: "" });
        dispatch({ type: "CHANGE_CUSTOM", custom: "Names: Sunita & Raju" });
        dispatch({ type: "CHANGE_EMOJIS", emojis: true });
        dispatch({ type: "CHANGE_HASHTAGS", hashtags: false });
        dispatch({ type: "CHANGE_CTA", cta: false });
        dispatch({ type: "CHANGE_LENGTH", length: "40" });
    }, []);


    return (
        <>
            <Typography variant="h4" component="h1" pb={2} id="tool">Try it!</Typography>

            <UploadImage photo="india" />

            <CaptionOptions />
            <SubmitButton image="india" />
            <Content />

            <Box pb={2} pt={2}></Box>

            <RegisterButton />

            <Box pb={2} pt={2}></Box>

            <Typography variant="h5" component="h4" pb={1} pt={2}>Also try out Demos for:</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <GeneratedWithAI />
            </Box>
        </>
    );
}

export default India;