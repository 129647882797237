
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

const GeneratedWithAI = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {isMobile ?
                <Stack direction="column" spacing={2}>

                    <a href='/online-shop' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Online Shops</Typography>
                    </a>
                    <a href='/business' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Business</Typography>
                    </a>
                    <a href='/facebook' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Facebook</Typography>
                    </a>
                    <a href='/india' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >India / Hindi</Typography>
                    </a>
                    <a href='/arabic' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Arabic</Typography>
                    </a>
                    <a href='/business-de' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >German / Deutsch</Typography>
                    </a>
                    <a href='/fashion' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Fashion</Typography>
                    </a>
                    <a href='/photography' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Photographers</Typography>
                    </a>
                    <a href='/reels' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >Instagram Reel</Typography>
                    </a>
                    <a href='/tiktok' rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Typography sx={{
                            textDecoration: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                        }} >TikTok videos</Typography>
                    </a>
                </Stack>
                :
                <Stack direction="row" spacing={2}>
                    < Stack direction="column" spacing={2} >
                        <a href='/online-shop' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Online Shops</Typography>
                        </a>
                        <a href='/business' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Business</Typography>
                        </a>
                    </Stack >
                    < Stack direction="column" spacing={2} >
                        <a href='/facebook' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Facebook</Typography>
                        </a>
                        <a href='/india' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >India / Hindi</Typography>
                        </a>
                    </Stack >
                    < Stack direction="column" spacing={2} >
                        <a href='/arabic' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Arabic</Typography>
                        </a>
                        <a href='/business-de' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >German / Deutsch</Typography>
                        </a>
                    </Stack >
                    < Stack direction="column" spacing={2} >
                        <a href='/fashion' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Fashion</Typography>
                        </a>
                        <a href='/photography' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Photographers</Typography>
                        </a>
                    </Stack>
                    <Stack direction="column" spacing={2} >
                        <a href='/reels' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >Instagram Reel</Typography>
                        </a>
                        <a href='/tiktok' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Typography sx={{
                                textDecoration: "none",
                                fontWeight: "bold",
                                color: "primary.main",
                            }} >TikTok videos</Typography>
                        </a>
                    </Stack>
                </Stack>
            }

        </>
    );
}

export default GeneratedWithAI;