import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

const Investor: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Typography variant="h4" component="h1" pb={2}>To all investors</Typography>

            <Typography variant="body1" pb={4}>
                imagetocaption.ai is currently fully bootstrapped. It started as a side project and has almost no operational cost. At the moment, we are not actively seeking external funding.
                <br /><br/>
                However, things may change in the future. If you're interested in our journey and would like to explore potential acquisition or investments further down the road, we would be delighted to stay connected!
                <br /><br/>
                Email us: info@imagetocaption.ai
            </Typography>
        </>
    );
};

export default Investor;