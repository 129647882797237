import React, { createContext, useReducer } from "react";

const initialState: iInitialState = {
    bucketRegion: "eu-central-1",
    imageUrl: "https://image-to-caption-permament-uploadstore.s3.eu-central-1.amazonaws.com/leenor1.png",
    imageName: "leenor1.png",
    bucketName: "image-to-caption-permament-uploadstore",
    language: "en",
    plattform: "Instagram",
    theme: "sale",
    setting: "",
    targetaudience: "",
    mood: "happy",
    formofadress: "",
    custom: "",
    emojis: true,
    hashtags: true,
    cta: false,
    length: "30",
    responseLoading: false,
    getCaptionButtonDisabled: false,
    imageUploading: false,
    imageUploaded: false,
    responseCaption: "",
    notificationBarHidden: true,
    paymentPeriod: "",
};
interface iInitialState {
    bucketRegion: string,
    imageUrl: string,
    imageName: string,
    bucketName: string,
    language: string,
    plattform: string,
    theme: string,
    setting: string,
    targetaudience: string,
    mood: string,
    formofadress: string,
    custom: string,
    emojis: boolean | null,
    hashtags: boolean | null,
    cta: boolean | null,
    length: string,
    responseLoading: boolean,
    getCaptionButtonDisabled: boolean,
    imageUploading: boolean,
    imageUploaded: boolean,
    responseCaption: string,
    notificationBarHidden: boolean,
    paymentPeriod: string
}
const defaultDispatch: React.Dispatch<Action> = () => initialState;

export const ImageContext = createContext(initialState);
export const ImageDispatchContext = createContext(defaultDispatch);

type Action =
    | { type: 'CHANGE_URL', imageUrl: string }
    | { type: 'CHANGE_LOADING', responseLoading: boolean }
    | { type: 'CHANGE_CAPTION', responseCaption: string }
    | { type: "CHANGE_FILENAME", imageName: string }
    | { type: "CHANGE_BUTTON", getCaptionButtonDisabled: boolean }
    | { type: "CHANGE_LANGUAGE", language: string }
    | { type: "CHANGE_PLATTFORM", plattform: string }
    | { type: "CHANGE_THEME", theme: string }
    | { type: "CHANGE_SETTING", setting: string }
    | { type: "CHANGE_TARGETAUDIENCE", targetaudience: string }
    | { type: "CHANGE_MOOD", mood: string }
    | { type: "CHANGE_FORMOFADRESS", formofadress: string }
    | { type: "CHANGE_CUSTOM", custom: string }
    | { type: "CHANGE_EMOJIS", emojis: boolean }
    | { type: "CHANGE_HASHTAGS", hashtags: boolean }
    | { type: "CHANGE_CTA", cta: boolean }
    | { type: "CHANGE_LENGTH", length: string }
    | { type: "UPLOAD", imageUploading: boolean, imageUploaded: boolean }
    | { type: "RESET" }
    | { type: 'CHANGE_NOTIFICATIONBAR_HIDDEN', notificationBarHidden: boolean }
    | { type: 'CHANGE_PAYMENT_PERIOD', paymentPeriod: string };

function reducer(state: any, action: Action) {
    switch (action.type) {
        case "CHANGE_URL":
            return { ...state, imageUrl: action.imageUrl };
        case "CHANGE_LOADING":
            return { ...state, responseLoading: action.responseLoading };
        case "CHANGE_CAPTION":
            return { ...state, responseCaption: action.responseCaption };
        case "CHANGE_FILENAME":
            return { ...state, imageName: action.imageName };
        case "CHANGE_BUTTON":
            return { ...state, getCaptionButtonDisabled: action.getCaptionButtonDisabled };
        case "CHANGE_LANGUAGE":
            return { ...state, language: action.language };
        case "CHANGE_THEME":
            return { ...state, theme: action.theme };
        case "CHANGE_SETTING":
            return { ...state, setting: action.setting };
        case "CHANGE_TARGETAUDIENCE":
            return { ...state, targetaudience: action.targetaudience };
        case "CHANGE_MOOD":
            return { ...state, mood: action.mood };
        case "CHANGE_FORMOFADRESS":
            return { ...state, formofadress: action.formofadress };
        case "CHANGE_CUSTOM":
            return { ...state, custom: action.custom };
        case "CHANGE_PLATTFORM":
            return { ...state, plattform: action.plattform };
        case "CHANGE_EMOJIS":
            return { ...state, emojis: action.emojis };
        case "CHANGE_HASHTAGS":
            return { ...state, hashtags: action.hashtags };
        case "CHANGE_CTA":
            return { ...state, cta: action.cta };
        case "CHANGE_LENGTH":
            return { ...state, length: action.length };
        case "UPLOAD":
            return { ...state, imageUploading: action.imageUploading, imageUploaded: action.imageUploaded };
        case "RESET":
            return { ...state, imageUrl: "", imageName: "", responseLoading: false, responseCaption: "", getCaptionButtonDisabled: true, imageUploading: false, imageUploaded: false };
        case "CHANGE_NOTIFICATIONBAR_HIDDEN":
            return { ...state, notificationBarHidden: action.notificationBarHidden };
        case "CHANGE_PAYMENT_PERIOD":
            return { ...state, paymentPeriod: action.paymentPeriod };
        default:
            return state;
    }
}

export function ImageProvider(props: { children: any; }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ImageContext.Provider value={state}>
            <ImageDispatchContext.Provider value={dispatch}>
                {props.children}
            </ImageDispatchContext.Provider>
        </ImageContext.Provider>
    )
}